var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("z-autocomplete", {
    attrs: {
      hideDetails: true,
      label: "Templates",
      items: _vm.templateList,
      itemText: "name",
      itemValue: "id",
      "return-object": "",
    },
    on: {
      change: function ($event) {
        _vm.$emit("select-template", _vm.template)
        _vm.template = ""
      },
    },
    model: {
      value: _vm.template,
      callback: function ($$v) {
        _vm.template = $$v
      },
      expression: "template",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }