<template>
  <z-autocomplete
    v-model="template"
    :hideDetails="true"
    label="Templates"
    :items="templateList"
    itemText="name"
    itemValue="id"
    return-object
    @change="
      $emit('select-template', template);
      template = '';
    "
  />
</template>

<script>
export default {
  inject: ["templateList"],
  data() {
    return {
      template: null,
    };
  },
};
</script>
